import { createApp } from 'vue'
import App from './App.vue'


// 1. 引入你需要的组件
import { Toast } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css';

const app = createApp();

// 3. 注册你需要的组件
app.use(Toast);




createApp(App).mount('#app')
