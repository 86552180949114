<template>
  <div>


    <div class="notice">AI解读报告仅供参考，以医生结论为准</div>

    <div class="chatBox" ref="chatContainer">


      <div class="chatBox-middle">
        <div class="chatInfo">

          <div class="chatUser-box" v-for="(item, index) in newData" :key="index"
            :class="[current == item.id ? 'chatUser-box1' : 'chatUser-box']">
            <div class="chatUser-box-img">
              <img :src="item.images" />
            </div>
            <div class="chatUser-info">
              <!-- <div class="chatUser-info-name" :class="[current == item.id ? 'chatUser-info-name1' : 'chatUser-info-name']">
                <span>{{ item.name }}</span><span class="nowDate">{{ item.time }}</span>
              </div> -->
              <div class="chatUser-info-text"
                :class="[current == item.id ? 'chatUser-info-text1' : 'chatUser-info-text']">
                <span v-if="item.is_pic == 0" v-html="item.message"></span>
                <span v-if="item.is_pic == 1"><img width='100%' :src="item.message"></span>

              </div>
            </div>
          </div>

        </div>
      </div>

    </div>




    <div v-if="qywechat_url3" style="position:fixed;bottom:130px;right:20px;">
      <a :href="qywechat_url3">
        <div>
          <img src="../src/assets/kefu.png" style="width: 50px;height: 50px;" alt="" />
        </div>
        <div style="font-size: 13px;text-align: center;color:#666;">
          在线客服
        </div>
      </a>
    </div>




    <div>


      <input v-show="false" ref="fileRef" type="file"  @change="fileChange">
      <input v-show="false" ref="fileRefPhoto" type="file"  accept="image/*" capture @change="fileChange">


      <div class="search bar6">
        <div class="form">
          <input type="text" v-bind:value="value" v-on:input="changeValue" placeholder="写下你想问的..." />
          <div class="submit" v-if="value">
            <button type="submit" @click="xunfei()"></button>
          </div>
        </div>

        <div class="gongneng">
          <!-- <div class="item" @click="refresh()">
            <img src="../src/assets/icon-add.svg" />
            刷新
          </div> -->

          <div class="item" @click="paizhao()">
            <div><img class="image" src="../src/assets/paizhao.png" /></div>
            <div>拍照</div>
          </div>

          <div class="item" @click="upload()">
            <div><img class="image" src="../src/assets/xiangce.png" /></div>
            <div>上传图片</div>
          </div>
        </div>

      </div>
    </div>


    <!-- https://work.weixin.qq.com/kfid/kfc45ccc4d4364f1639 -->

   

  </div>
</template>

<script>




import { showToast } from 'vant';
const axios = require('axios');




export default {

  data() {
    return {
      value: "",
      is_pic: false,
      accumulatedData: "",

      current: 1,
      newData: [

      

      ],
      eventSource: null,
      url: '',

      qywechat_url3:''
    };
  },
  created() {
    // 获取当前 URL
    const urlParams = new URLSearchParams(window.location.search);

    // 通过参数名获取对应的值
    const url = urlParams.get('url');
    console.log(url);


    this.url = url;
    console.log(this.url)



    if (this.url) {


      showToast('正在解读报告')


      // 先追加一个数组

      // this.newData.push({
      //   id: 1,
      //   name: '咨询者',
      //   time: '2021-4-23 18:00:24 星期五',
      //   images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/901ea2ea90897728e535553f92830943.png',
      //   is_pic: 0,
      //   message: '正在解读你的检验报告'
      // });



      // 不需要报告图片

      this.value = this.url;

      this.is_pic = true;

      this.xunfei(true);
    }else{

      // 空的情况

      this.newData.push({
        id: 3,
        name: '商客',
        time: '2021-4-23 18:00:24 星期五',
        images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/b839490ce9c4b1923fb8b49afdadd300.jpg',
        is_pic: 0,
        message: '<span style="display: inline;">请问需要报告解读吗？请点击右下角 <img style="display: inline; vertical-align: middle;" src="/icon-camera.svg" width="26px"> 上传报告</span>'
      });


    }


  },

  computed: {

  },
  methods: {

    voice() {
      showToast('正在开发中')
    },

    scrollToBottom() {

      // 使用 $nextTick 确保所有的 DOM 更新完成
      // this.$nextTick(() => {
      //   const container = this.$refs.scrollContainer;
      //   container.scrollTop = container.scrollHeight;
      // });


      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        const lastMessage = container.lastElementChild;
        if (lastMessage) {
          lastMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      });



    },

    refresh() {
      this.value = '';

      this.newData = [
        {
          id: 3,
          name: '商客',
          time: '2021-4-23 18:00:24 星期五',
          images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/b839490ce9c4b1923fb8b49afdadd300.jpg',
          is_pic: 0,
          message: '请问有什么需要帮助的吗？'
        }
      ];

      showToast('当前已经是新回话了，请开始提问题吧')    //必须在组件内使用
    },

    upload() {

      console.log('upload')

      this.$refs.fileRef.dispatchEvent(new MouseEvent('click'))  //弹出选择本地文件

      // this.$refs.fileRef.setAttribute('capture', 'environment'); // 使用后置摄像头
      
    },


    paizhao(){
      console.log('upload')

      this.$refs.fileRefPhoto.dispatchEvent(new MouseEvent('click'))  //弹出选择本地文件
      this.$refs.fileRefPhoto.setAttribute('capture', 'environment'); // 使用后置摄像头

    },

    

    fileChange(e) {


      // 阻止发生默认行为
      event.preventDefault();
      let formData = new FormData()
      let file = this.$refs.fileRef.files[0]

      console.log(file)

      formData.append('file', file)

      this.onUpload(formData)

    },


    // 上传文件
    onUpload(formData) {





      let config = {
        headers: { 'Content-Type': 'multipart/form-data' } //这里是重点，需要和后台沟通好请求头，Content-Type不一定是这个值
      }; //添加请求头
      axios.post('https://wxgy5.yjxmall.com/api/portal/upload/upload', formData, config)
        .then(response => {

          if (response.data.code == 1) {
            showToast('上传成功,正在解读报告')

            this.value = response.data.data.path;




            // 先追加一个数组
            this.newData.push({
              id: 1,
              name: '咨询者',
              time: '2021-4-23 18:00:24 星期五',
              images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/901ea2ea90897728e535553f92830943.png',
              is_pic: 1,
              message: this.value
            });



            this.is_pic = true;

            this.xunfei(true);

          } else {
            showToast('上传失败')
          }

          console.log(response.data);

        })

      // postUpload(formData).then((res) => {
      //   this.mdl.pic = res.result.uri
      //   showToast('上传成功')
      // }).catch((e) => {
      //   showToast(e.message)
      // })



    },

    changeValue(e) {
      this.value = e.target.value;
    },




    async baidu(is_pic = false) {
      try {

        if (this.value == '' || !this.value) {
          return false;
        }

        let keyword = this.value;

        this.value = '';
        this.accumulatedData = '';


        if (is_pic == false) {
          // 先追加一个数组
          this.newData.push({
            id: 1,
            name: '咨询者',
            time: '2021-4-23 18:00:24 星期五',
            images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/901ea2ea90897728e535553f92830943.png',
            is_pic: 0,
            message: keyword
          });

        }




        // 先追加一个数组
        this.newData.push({
          id: 3,
          name: '商客',
          time: '2021-4-23 18:00:24 星期五',
          images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/b839490ce9c4b1923fb8b49afdadd300.jpg',
          is_pic: 0,
          message: '正在解读...'
        });



        /* eslint-disable */
        const response = await fetch(
          // "http://localhost:5000/?question=" + keyword,
          "https://wxgy5.yjxmall.com/api/portal/bot/baidu?question=" + keyword,
          {
            method: "POST",
          }
        );

        if (!response.body) {
          throw new Error("Response body is empty.");
        }

        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();


        let accumulatedData = "";
        if (is_pic == true) {
          accumulatedData = "报告解读结果：<br><br>";
        }


        while (true) {
          const { value, done } = await reader.read();

          if (done) break;



          try {

            let cleanValue = decodeURIComponent(value);


            console.log(cleanValue)


            let formattedText = cleanValue.replace("data:event:message", "");

            console.log(formattedText)

            formattedText = formattedText.replace("event:message ", "");


            formattedText = formattedText.replace("data:", "");
            console.log(formattedText)


            let body = formattedText;


            if (body && body != undefined) {
              accumulatedData += body;
            }

            accumulatedData = accumulatedData.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

            accumulatedData = accumulatedData.replace('\n', '<br>');

            const lastIndex = this.newData.length - 1;
            // 修改最后一个元素的值

            this.newData[lastIndex] = {
              id: 2,
              name: '发哥',
              time: '2021-4-23 18:00:05 星期五',
              images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/b839490ce9c4b1923fb8b49afdadd300.jpg',
              is_pic: 0,
              message: accumulatedData
            };


            this.scrollToBottom();



          } catch (e) {

            console.log(e)

          }


        }


      } catch (error) {
        console.error("Error connecting to SSE:", error);
      }
    },



    async api(is_pic = false) {
      try {

        if (this.value == '' || !this.value) {
          return false;
        }

        let keyword = this.value;

        this.value = '';
        this.accumulatedData = '';

        /* eslint-disable */
        const response = await fetch(
          "http://localhost:5002/?question=" + keyword,
          // "https://api5000.c3w.cc/?question=" + keyword,
          {
            method: "POST",
          }
        );

        if (!response.body) {
          throw new Error("Response body is empty.");
        }

        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();

        let accumulatedData = "";



        if (is_pic == false) {
          // 先追加一个数组
          this.newData.push({
            id: 1,
            name: '咨询者',
            time: '2021-4-23 18:00:24 星期五',
            images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/901ea2ea90897728e535553f92830943.png',
            is_pic: 0,
            message: keyword
          });
        }


        // 先追加一个数组
        this.newData.push({
          id: 3,
          name: '商客',
          time: '2021-4-23 18:00:24 星期五',
          images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/b839490ce9c4b1923fb8b49afdadd300.jpg',
          is_pic: 0,
          message: '正在思考...'
        });

        while (true) {
          const { value, done } = await reader.read();

          if (done) break;


          let cleanValue = value;

          try {

            let formattedText = cleanValue;
            formattedText = formattedText.replace("data:", "");


            let result = JSON.parse(formattedText);



            accumulatedData += result.result;


            accumulatedData = accumulatedData.replace('\\n', '\n');

            accumulatedData = accumulatedData.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');


            const lastIndex = this.newData.length - 1;
            // 修改最后一个元素的值

            this.newData[lastIndex] = {
              id: 2,
              name: '发哥',
              time: '2021-4-23 18:00:05 星期五',
              images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/b839490ce9c4b1923fb8b49afdadd300.jpg',
              is_pic: 0,
              message: accumulatedData
            };


            this.scrollToBottom();

          } catch (e) {

          }


        }


      } catch (error) {
        console.error("Error connecting to SSE:", error);
      }
    },




    async xunfei(is_pic = false) {
      try {

        if (this.value == '' || !this.value) {
          return false;
        }

        let keyword = this.value;

        this.value = '';
        this.accumulatedData = '';



        if (is_pic == false) {
          // 先追加一个数组
          this.newData.push({
            id: 1,
            name: '咨询者',
            time: '2021-4-23 18:00:24 星期五',
            images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/901ea2ea90897728e535553f92830943.png',
            is_pic: 0,
            message: keyword
          });
        }


        // 先追加一个数组
        this.newData.push({
          id: 3,
          name: '商客',
          time: '2021-4-23 18:00:24 星期五',
          images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/b839490ce9c4b1923fb8b49afdadd300.jpg',
          is_pic: 0,
          message: '正在解读...'
        });




        /* eslint-disable */
        const response = await fetch(
          // "http://localhost:5000/?question=" + keyword,
          // "https://api5000.c3w.cc/?question=" + keyword,
          "https://wxgy5.yjxmall.com/api/portal/bot/xunfei?question=" + keyword,
          {
            method: "POST",
          }
        );

        if (!response.body) {
          throw new Error("Response body is empty.");
        }

        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();

        let accumulatedData = "";








        while (true) {
          const { value, done } = await reader.read();

          if (done) break;



          let cleanValue = value;


          console.log(cleanValue)

          try {

            const formattedText = decodeURIComponent(cleanValue);



            accumulatedData += formattedText;



            accumulatedData = accumulatedData.replace('\\n', '<br>');

            accumulatedData = accumulatedData.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

            console.log(accumulatedData);



            const lastIndex = this.newData.length - 1;
            // 修改最后一个元素的值

            this.newData[lastIndex] = {
              id: 2,
              name: '发哥',
              time: '2021-4-23 18:00:05 星期五',
              images: 'https://wxgy5.yjxmall.com/upload/ai/2024-07-23/b839490ce9c4b1923fb8b49afdadd300.jpg',
              is_pic: 0,
              message: accumulatedData
            };




            this.scrollToBottom();

          } catch (e) {

          }


        }


      } catch (error) {
        console.error("Error connecting to SSE:", error);
      }
    }


  },
};
</script>


<style>
.notice {
  position: fixed;
  top: 0;
  background-color: #f7823f;
  color: #fff;
  text-align: center;
  width: 80%;
  padding: 8px;
  border-radius: 10px;


  top: 8px;
  left: 50%;
  transform: translateX(-50%);

  font-size: 0.9rem;
}



.gongneng {
  display: flex;
  flex-wrap: wrap;
  display: -webkit-flex;
 height: 72px;
}



.gongneng>.item {

  flex: 1;
  height: 45px;
  text-align: center;
  padding-top: 5px;
}


.image{
  width: 26px;
  height: 26px;
}


.accumulatedData {
  padding: 10px;
  margin: 10px;
  padding-bottom: 150px;
  line-height: 26px;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;


  font-family: "Microsoft YaHei", "宋体", "Segoe UI", "Lucida Grande", Helvetica,
    Arial, sans-serif, FreeSans, Arimo;

  background-color: #f1f2f5;
}

#container {
  width: 100%;
  height: 820px;
  margin: 0 auto;
}

div.search {
  position: fixed;
  bottom: 0px;

  width: 100%;
}

.form {
  position: relative;

  margin: 10px;
}

input,
button {
  border: none;
  outline: none;
}

input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-left: 13px;
  font-size: 20px;
}

button {
  height: 50px;
  width: 50px;
  cursor: pointer;
  position: absolute;
  font-size: 20px;
}



/*搜索框6*/
.bar6 {
  background: #fff;
}

.bar6 input {
  background-color: #eeeff0;
  border-radius: 25px;
  top: 0;
  right: 0;
}


.bar6 button {
  background: #3363FF;
  border-radius: 0 5px 5px 0;
  width: 60px;
  top: 0;
  right: 0;
}

.bar6 button:before {
  content: "提交";
  font-size: 18px;
  color: #f9f0da;
}



.chatBox {



  height: 700px;
  /* 设置容器高度，使其可滚动 */
  overflow-y: auto;
  /* 垂直方向上出现滚动条 */


  height: auto;
  margin: 40px 0 100px 0;


  border-radius: 0.625rem;
}

.chatBox-top {
  width: 100%;
  height: 3.75rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background-color: #2B3D63;
}

.chatBox-top-imgBox {
  margin-left: 1.25rem;
}

.chatBox-top-text {
  margin-left: 1.25rem;
  font-size: 1rem;
  color: #fff;
}

.chatBox-middle {
  width: 100%;



}


.chatBox-infoDesk {
  width: 100%;
  height: 10rem;
}

.chatBox-textarea {
  width: 100%;
  height: 6.25rem;
}

.chatBox-sendOut {
  margin-top: 0.625rem;
  width: 100%;
  height: 3.125rem;
  text-align: right;
}

.sendOut {
  padding: 0 1.25rem;
  height: 2.1875rem;
  margin: 0.3125rem 1.25rem 0 0;
}

.chatInfo {
  width: 94%;
  height: 94%;
  margin: 1.25rem auto;
  overflow: auto;
}

.chatUser-box {
  width: 100%;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
}


.chatUser-box-img {
  display: flex;
  width: 30px;
  height: 30px;
}

.chatUser-info {
  margin: 0 0.5rem;
}

.chatUser-info-name {
  font-size: 0.875rem;
  color: #000;
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.nowDate {
  margin: 0 0.625rem;
}

.chatUser-info-text {
  margin-top: 0.3125rem;
  max-width: 20rem;
  padding: 0.5rem;
  background-color: #fff;

  border-radius: 0.5rem;
  float: left;
  table-layout: fixed;
  word-break: break-all;
  overflow: hidden;
}

.chatUser-info-text span {
  font-size: 1.0rem;
  line-height: 1.6625rem;
}

.chatUser-box1 {
  flex-direction: row-reverse;
}

.chatUser-info-name1 {
  flex-direction: row-reverse;
}

.chatUser-info-text1 {
  float: right;
}
</style>